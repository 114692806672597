import { useParams } from "react-router-dom";
import React, { FC } from "react";
import { useFormikContext } from "formik";
import { formatISO, parse } from "date-fns";

import { DatabaseRow } from "@Shape-Digital/kudzu-data/lib/types/common";
import { LogInFormPersonType } from "@Shape-Digital/kudzu-data/lib/types/actions";

import AppointmentCheckoutPatientForm, {
  PatientFormValues,
} from "../AppointmentCheckoutPatientForm";
import AppointmentCheckoutGuardianForm, {
  GuardianFormValues,
} from "../AppointmentCheckoutGuardianForm";
import AppointmentCheckoutOtherPersonForm, {
  OtherPersonFormValues,
} from "../AppointmentCheckoutOtherPersonForm";
import useFirebaseAppFunction from "../../../hooks/useFirebaseAppFunction";
import { CHECKOUT_DATE_FORMAT } from "../AppointmentCheckoutFormSectionWrapper/getValidationSchema";

const getPhoneNumber = (str: string): string => {
  return `+${str.replace(/\D/g, "")}`;
};

const getDateOfBirth = (str: string) =>
  formatISO(parse(str, CHECKOUT_DATE_FORMAT, Date.now()), {
    representation: "date",
  });

type AppointmentCheckoutFormSectionProps = {
  personType: LogInFormPersonType | null;
  onNotPossibleAnswerClick: (question: string) => void;
  onEmailBlur?: (email: string, valid: boolean) => void | Promise<void>;
  isEmailLoading?: boolean;
  isCheckDataShown?: boolean;
  isEmailInUse?: boolean;
  isCenterUser: boolean;
  onSelectCustomer: (customer: DatabaseRow<"customers">) => void;
};

const formComponents = {
  patient: AppointmentCheckoutPatientForm,
  legalGuardian: AppointmentCheckoutGuardianForm,
  otherPerson: AppointmentCheckoutOtherPersonForm,
};

const AppointmentCheckoutFormSection: FC<
  AppointmentCheckoutFormSectionProps
> = ({
  personType,
  onNotPossibleAnswerClick,
  onEmailBlur,
  isEmailLoading,
  isCheckDataShown,
  isEmailInUse,
  isCenterUser,
  onSelectCustomer,
}) => {
  const { appointmentId } = useParams();

  const { values } = useFormikContext();

  const appointmentCaptureCartData = useFirebaseAppFunction(
    "appointmentCaptureCartData",
  );

  if (!personType || !appointmentId) return null;

  const FormComponent = formComponents[personType];

  const onBlur = async () => {
    switch (personType) {
      case "patient":
        // eslint-disable-next-line no-case-declarations
        const patientFormValues = values as PatientFormValues;

        await appointmentCaptureCartData({
          appointmentId,
          creatorType: "patient",
          email: patientFormValues.email,
          firstName: patientFormValues.firstName,
          lastName: patientFormValues.lastName,
          dateOfBirth: patientFormValues.dateOfBirth
            ? getDateOfBirth(patientFormValues.dateOfBirth)
            : "",
          phoneNumber: patientFormValues.phoneNumber
            ? getPhoneNumber(patientFormValues.phoneNumber)
            : "",
        });
        break;
      case "legalGuardian":
        // eslint-disable-next-line no-case-declarations
        const guardianFormValues = values as GuardianFormValues;

        await appointmentCaptureCartData({
          appointmentId,
          creatorType: "legalGuardian",
          guardianEmail: guardianFormValues.guardianEmail,
          guardianFirstName: guardianFormValues.guardianFirstName,
          guardianLastName: guardianFormValues.guardianLastName,
          firstName: guardianFormValues.firstName,
          lastName: guardianFormValues.lastName,
          dateOfBirth: guardianFormValues.dateOfBirth
            ? getDateOfBirth(guardianFormValues.dateOfBirth)
            : "",
          guardianPhoneNumber: guardianFormValues.guardianPhoneNumber
            ? getPhoneNumber(guardianFormValues.guardianPhoneNumber)
            : "",
        });
        break;
      case "otherPerson":
        // eslint-disable-next-line no-case-declarations
        const otherPersonFormValues = values as OtherPersonFormValues;

        await appointmentCaptureCartData({
          appointmentId,
          creatorType: "otherPerson",
          email: otherPersonFormValues.email,
          otherPersonEmail: otherPersonFormValues.otherPersonEmail,
          firstName: otherPersonFormValues.firstName,
          lastName: otherPersonFormValues.lastName,
          otherPersonFirstName: otherPersonFormValues.otherPersonFirstName,
          otherPersonLastName: otherPersonFormValues.otherPersonLastName,
          dateOfBirth: otherPersonFormValues.dateOfBirth
            ? getDateOfBirth(otherPersonFormValues.dateOfBirth)
            : "",
          phoneNumber: otherPersonFormValues.phoneNumber
            ? getPhoneNumber(otherPersonFormValues.phoneNumber)
            : "",
        });
        break;
      default:
        break;
    }
  };

  return (
    <FormComponent
      onNotPossibleAnswerClick={onNotPossibleAnswerClick}
      onEmailBlur={onEmailBlur}
      isEmailLoading={isEmailLoading}
      isCheckDataShown={isCheckDataShown}
      isEmailInUse={isEmailInUse}
      isCenterUser={isCenterUser}
      onSelectCustomer={onSelectCustomer}
      onBlur={onBlur}
    />
  );
};

export default AppointmentCheckoutFormSection;
