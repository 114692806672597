import {
  SUPABASE_TOKEN,
  SUPABASE_CENTER_USER_TOKEN_KEY,
} from "../common/constants";

export const setSupabaseAccessToken = (token: string): void => {
  localStorage.setItem(SUPABASE_TOKEN, token);
};

export const setCenterUserSupabaseToken = (token: string): void => {
  sessionStorage.setItem(SUPABASE_CENTER_USER_TOKEN_KEY, token);
};
