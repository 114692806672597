import { DatabaseRow } from "@Shape-Digital/kudzu-data/lib/types/common";
import React, { FC, FocusEventHandler } from "react";
import { useFormikContext } from "formik";
import Grid from "../../Unknown/Grid";
import Typography from "../../Unknown/Typography";
import useTranslations from "./useTranslations";
import FormikTextField from "../../Unknown/FormikTextField";
import TextField from "../../Unknown/TextField";
import InputAdornment from "../../Unknown/InputAdornment";
import CircularProgress from "../../Unknown/CircularProgress";
import Box from "../../Unknown/Box";
import Alert from "../../Unknown/Alert";
import FormikRadioGroup, {
  FormikRadioGroupOption,
} from "../../Unknown/FormikRadioGroup";
import FormikPhoneInput from "../../Unknown/FormikPhoneInput";
import CustomerSearchInput from "../../Customer/CustomerSearchInput";

export type PatientFormValues = {
  email: string;
  phoneNumber: string;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  pregnant: "yes" | "no" | "";
  wearingDevice: "yes" | "no" | "";
};

type AppointmentCheckoutPatientFormProps = {
  onNotPossibleAnswerClick?: (question: string) => void;
  onEmailBlur?: (email: string, valid: boolean) => void | Promise<void>;
  isEmailLoading?: boolean;
  isCheckDataShown?: boolean;
  isEmailInUse?: boolean;
  isCenterUser?: boolean;
  onBlur: () => void;
  onSelectCustomer?: (customer: DatabaseRow<"customers">) => void;
};

const AppointmentCheckoutPatientForm: FC<
  AppointmentCheckoutPatientFormProps
> = ({
  onNotPossibleAnswerClick,
  onEmailBlur,
  isEmailLoading,
  isCheckDataShown,
  isEmailInUse,
  isCenterUser,
  onBlur,
  onSelectCustomer,
}) => {
  const translations = useTranslations();

  const { isSubmitting, setFieldValue, handleBlur, values, errors, touched } =
    useFormikContext<PatientFormValues>();

  const { answerYes, answerNo, wearingDeviceHeader } = translations;
  // Uncomment the code if there is a need for displaying pregnancy question in the future.
  // const onPregnantClick = () => {
  //   if (onNotPossibleAnswerClick) {
  //     onNotPossibleAnswerClick(pregnantHeader);
  //   }
  // };

  const onWearingDeviceClick = () => {
    if (onNotPossibleAnswerClick) {
      onNotPossibleAnswerClick(wearingDeviceHeader);
    }
  };

  // const pregnantOptions: FormikRadioGroupOption[] = [
  //   { value: "yes", label: answerYes, onClick: onPregnantClick },
  //   { value: "no", label: answerNo },
  // ];

  const wearingDeviceOptions: FormikRadioGroupOption[] = [
    { value: "yes", label: answerYes, onClick: onWearingDeviceClick },
    { value: "no", label: answerNo },
  ];

  const handleEmailBlur: FocusEventHandler<HTMLInputElement> = async (e) => {
    if (onEmailBlur) {
      onEmailBlur(e.target.value, !errors.email);
    }
    onBlur();
    handleBlur(e);
  };

  const isEmailInUseError = isEmailInUse ? translations.authErrorMessage : null;

  return (
    <Grid container spacing={8} direction="column">
      <Grid item container spacing={4} direction="column">
        <Grid item>
          <Typography variant="h3">
            {translations.yourInformationHeader}
          </Typography>
        </Grid>
        {isCenterUser && (
          <Grid item>
            <CustomerSearchInput onSelectCustomer={onSelectCustomer} />
          </Grid>
        )}
        <Grid item>
          <TextField
            name="email"
            type="email"
            onChange={({ target }) =>
              setFieldValue("email", target.value.toLocaleLowerCase())
            }
            onBlur={handleEmailBlur}
            value={values.email}
            label={translations.emailLabel}
            disabled={isEmailLoading || isSubmitting}
            fullWidth
            InputProps={{
              endAdornment: isEmailLoading ? (
                <InputAdornment position="end">
                  <CircularProgress size={20} />
                </InputAdornment>
              ) : undefined,
            }}
            InputLabelProps={{ shrink: !!values.email }}
            error={touched.email && Boolean(errors.email || isEmailInUseError)}
            helperText={touched.email && (errors.email || isEmailInUseError)}
            autoComplete="off"
            required
          />
        </Grid>
        <Grid item>
          <FormikPhoneInput
            name="phoneNumber"
            autoComplete="off"
            required
            onBlur={onBlur}
            fullWidth
            disabled={isSubmitting}
            label={translations.phoneNumberLabel}
          />
        </Grid>
        <Grid item>
          <FormikTextField
            autoComplete="off"
            required
            fullWidth
            name="firstName"
            onBlur={onBlur}
            label={translations.firstNameLabel}
            disabled={isSubmitting}
          />
        </Grid>
        <Grid item>
          <FormikTextField
            autoComplete="off"
            required
            fullWidth
            name="lastName"
            onBlur={onBlur}
            label={translations.lastNameLabel}
            disabled={isSubmitting}
          />
        </Grid>
        <Grid item>
          <FormikTextField
            autoComplete="off"
            required
            fullWidth
            name="dateOfBirth"
            type="date"
            onBlur={onBlur}
            label={translations.dateOfBirthLabel}
            disabled={isSubmitting}
            InputLabelProps={{ shrink: true }}
            helperText={translations.dateOfBirthHelper}
            placeholder={translations.dateOfBirthPlaceholder}
          />
        </Grid>
        {isCheckDataShown && (
          <Grid item>
            <Box mt={4}>
              <Alert severity="info">{translations.checkUpToDateData}</Alert>
            </Box>
          </Grid>
        )}
      </Grid>
      {/* <Grid item container spacing={4} direction="column">
        <Grid item>
          <Typography variant="h3">{translations.pregnantHeader}</Typography>
        </Grid>
        <Grid item>
          <FormikRadioGroup
            name="pregnant"
            isDisabled={isSubmitting}
            options={pregnantOptions}
          />
        </Grid>
      </Grid> */}
      <Grid item container spacing={4} direction="column">
        <Grid item>
          <Typography variant="h3">
            {translations.wearingDeviceHeader}
          </Typography>
        </Grid>
        <Grid item>
          <FormikRadioGroup
            name="wearingDevice"
            isDisabled={isSubmitting}
            options={wearingDeviceOptions}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AppointmentCheckoutPatientForm;
