import React, { FC } from "react";
import { useIntl } from "react-intl";

import FormControlLabel from "../FormControlLabel";
import Typography from "../Typography";
import Checkbox, { CheckboxProps } from "../Checkbox";
import Paper from "../Paper";

import messages from "./messages";
import useStyles from "./useStyles";

export type CheckboxGroupOption = {
  value: string;
  label: string;
  price: number;
  currencyCode: string;
  checked?: boolean;
};

interface CheckboxGroupProps extends CheckboxProps {
  title?: string;
  dense?: boolean;
  options: CheckboxGroupOption[];
}

const CheckboxGroup: FC<CheckboxGroupProps> = ({
  title,
  options,
  dense,
  ...checkboxProps
}) => {
  const intl = useIntl();
  const classes = useStyles({ dense });

  if (!options?.length) {
    return null;
  }

  return (
    <Paper classes={{ root: classes.paper }} sx={{ padding: dense ? 2 : 4 }}>
      {title && (
        <Typography variant="h3" mb={2}>
          {title}
        </Typography>
      )}

      {options.map((option) => (
        <FormControlLabel
          key={option.value}
          control={<Checkbox {...checkboxProps} checked={option.checked} />}
          classes={{ root: classes.input }}
          value={option.value}
          label={
            <Typography sx={{ wordBreak: "break-word" }}>
              {intl.formatMessage(messages.label, {
                title: option.label,
              })}
              {intl.formatNumber(option.price, {
                style: "currency",
                currency: option.currencyCode,
              })}
            </Typography>
          }
        />
      ))}
    </Paper>
  );
};

export default CheckboxGroup;
