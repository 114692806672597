import React, { FC } from "react";
import { FormatDateOptions, useIntl } from "react-intl";
import Big from "big.js";
import getSymbolFromCurrency from "currency-symbol-map";
import { DatabaseRow } from "@Shape-Digital/kudzu-data/lib/types/common";

import Box from "../../Unknown/Box";
import Button from "../../Unknown/Button";
import Grid from "../../Unknown/Grid";
import Typography from "../../Unknown/Typography";
import Paper from "../../Unknown/Paper";
import messages from "./messages";
import useStyles from "./useStyles";
import { DEFAULT_CURRENCY_CODE } from "../../../common/constants";
import getCenterAddress from "../../../common/getCenterAddress";
import TextField from "../../Unknown/TextField";
import { SummaryPositionItem } from "./summaryPositionItem";
import { BookingAddOn } from "../AppointmentBookingContext/useBookingAddOns";

export type CenterSummary = {
  name: string;
  country: string;
  addresses: DatabaseRow<"center_addresses">[];
  state: string;
  city: string;
  postalCode: string;
  region: string;
  defaultCurrencyCode: string;
};

type AddOn = {
  name: string;
  price: number;
};

type CenterService = {
  name: string;
  price: number;
  side?: string;
  studyIds: string[];
  addOnIds: string[];
};

export interface AppointmentSummaryDetailsProps {
  selectedCenter: CenterSummary | null;
  selectedDateTime?: Date | null;
  selectedCenterServices?: CenterService[];
  selectedAddOns?: AddOn[];
  discountSum: number;
  priceWithDiscount: number;
  depositAmount: number;
  isSubmitButtonDisabled?: boolean;
  isSubmitLoading?: boolean;
  buttonSubmitText?: string;
  disableCenterShowing?: boolean;
  isCenterUser?: boolean;
  overriddenPrice?: string | null;
  overriddenTime?: string | null;
  overriddenDeposit?: string | null;
  isOverriddenPriceError?: boolean;
  isOverriddenDepositError?: boolean;
  isDaySelected?: boolean;
  centerAddOns: BookingAddOn[];
  onSubmitClick?: () => void | Promise<void>;
  onPriceOverride?: (newPrice: string) => void;
  onDepositOverride?: (newDeposit: string) => void;
  onTimeOverride?: (newTime: string) => void;
}

const dateFormatOptions: FormatDateOptions = {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
};

const AppointmentSummaryDetails: FC<AppointmentSummaryDetailsProps> = ({
  selectedCenter,
  selectedDateTime,
  selectedCenterServices = [],
  selectedAddOns = [],
  discountSum,
  priceWithDiscount,
  depositAmount,
  isOverriddenPriceError,
  isOverriddenDepositError,
  isSubmitButtonDisabled,
  isSubmitLoading,
  buttonSubmitText,
  disableCenterShowing,
  isCenterUser,
  overriddenPrice,
  overriddenDeposit,
  overriddenTime,
  isDaySelected,
  centerAddOns,
  onSubmitClick,
  onPriceOverride,
  onDepositOverride,
  onTimeOverride,
}) => {
  const { formatMessage, formatDate, formatTime, formatNumber } = useIntl();
  const classes = useStyles();

  const { name, defaultCurrencyCode } = selectedCenter || {};

  const isDateAndLocationShown =
    !disableCenterShowing && selectedDateTime && selectedCenter;

  const isSummaryPositionsShown =
    !!selectedAddOns.length || !!selectedCenterServices.length;

  const isDiscountShown = discountSum !== 0;

  const address = selectedCenter ? getCenterAddress(selectedCenter) : "";

  const currency = defaultCurrencyCode || DEFAULT_CURRENCY_CODE;
  const currencySymbol = getSymbolFromCurrency(currency);

  const totalPriceWithDiscountText = formatNumber(priceWithDiscount, {
    style: "currency",
    currency,
  });

  const depositAmountText = formatNumber(depositAmount, {
    style: "currency",
    currency,
  });

  const sideTranslations: Record<string, string> = {
    left: formatMessage(messages.sideLeft),
    right: formatMessage(messages.sideRight),
  };

  return (
    <>
      <Grid container spacing={5} direction="column">
        {isDateAndLocationShown && (
          <Grid item>
            <Paper classes={{ root: classes.locationPaper }}>
              <Typography mb={1}>
                {formatMessage(messages.location, {
                  location: name,
                })}
              </Typography>
              <Typography mb={4} variant="body2">
                {address}
              </Typography>
              <Typography>
                {formatMessage(messages.dateTime, {
                  date: formatDate(selectedDateTime, dateFormatOptions),
                  time: formatTime(selectedDateTime),
                })}
              </Typography>
            </Paper>
          </Grid>
        )}
        {isSummaryPositionsShown && (
          <Grid item>
            {selectedCenterServices.map((service) => {
              const {
                name: summaryPositionName,
                side,
                price,
                addOnIds,
                studyIds,
              } = service;

              const sideTranslation = side ? sideTranslations[side] : "";

              const serviceName = sideTranslation
                ? `${summaryPositionName} (${sideTranslation})`
                : summaryPositionName;

              const servicePrice = formatNumber(price, {
                style: "currency",
                currency: defaultCurrencyCode,
              });

              return (
                <>
                  <SummaryPositionItem
                    name={serviceName}
                    price={servicePrice}
                  />
                  {addOnIds?.map((serviceAddOnId) => {
                    const serviceAddOn = centerAddOns.find(
                      (addOn) => addOn.id === serviceAddOnId,
                    );
                    if (!serviceAddOn) return null;

                    const studiesCount = studyIds.length;
                    const addOnPrice = Big(serviceAddOn.price)
                      .times(studiesCount)
                      .toNumber();

                    const serviceAddOnName = `${serviceAddOn.name} for ${serviceName}`;
                    const serviceAddOnPrice = formatNumber(addOnPrice, {
                      style: "currency",
                      currency: defaultCurrencyCode,
                    });

                    return (
                      <SummaryPositionItem
                        key={serviceAddOnName}
                        name={serviceAddOnName}
                        price={serviceAddOnPrice}
                      />
                    );
                  })}
                </>
              );
            })}
            {selectedAddOns.map((summaryPosition) => {
              const { name: addOnName, price } = summaryPosition;
              const formattedPrice = formatNumber(price, {
                style: "currency",
                currency: defaultCurrencyCode,
              });

              return (
                <SummaryPositionItem
                  key={addOnName}
                  name={addOnName}
                  price={formattedPrice}
                />
              );
            })}
          </Grid>
        )}
        {isDiscountShown && (
          <Grid item>
            <Box display="flex" justifyContent="space-between" mb={2}>
              <Typography>{formatMessage(messages.discount)}</Typography>
              <Typography>
                -
                {formatNumber(discountSum, {
                  style: "currency",
                  currency: defaultCurrencyCode,
                })}
              </Typography>
            </Box>
          </Grid>
        )}
        {!!(isCenterUser && onPriceOverride) && (
          <Grid item>
            <TextField
              label={formatMessage(messages.priceOverrideLabel)}
              onChange={({ target }) => onPriceOverride(target.value)}
              value={overriddenPrice || ""}
              fullWidth
              error={isOverriddenPriceError}
              helperText={
                isOverriddenPriceError &&
                formatMessage(messages.priceShouldBeLower)
              }
              InputProps={{
                startAdornment: (
                  <Typography mr={2} color="text.secondary">
                    {currencySymbol}
                  </Typography>
                ),
                classes: {
                  input: classes.priceOverrideInput,
                },
              }}
            />
          </Grid>
        )}
        {!!(isCenterUser && onDepositOverride) && (
          <Grid item>
            <TextField
              label={formatMessage(messages.depositOverrideLabel)}
              onChange={({ target }) => onDepositOverride(target.value)}
              value={overriddenDeposit || ""}
              fullWidth
              error={isOverriddenDepositError}
              helperText={
                isOverriddenDepositError &&
                formatMessage(messages.depositShouldBeLower)
              }
              InputProps={{
                startAdornment: (
                  <Typography mr={2} color="text.secondary">
                    {currencySymbol}
                  </Typography>
                ),
                classes: {
                  input: classes.priceOverrideInput,
                },
              }}
            />
          </Grid>
        )}
        {!!(isCenterUser && onTimeOverride) && (
          <Grid item>
            <TextField
              label={formatMessage(messages.timeOverrideLabel)}
              onChange={({ target }) => onTimeOverride(target.value)}
              value={overriddenTime || ""}
              fullWidth
              placeholder=""
              type="time"
              disabled={!isDaySelected}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                classes: {
                  input: classes.priceOverrideInput,
                },
              }}
            />
          </Grid>
        )}
        <Grid item>
          <Box display="flex" justifyContent="space-between" mb={2}>
            <Typography fontWeight={700}>
              {formatMessage(messages.total)}
            </Typography>
            <Typography fontWeight={700}>
              {totalPriceWithDiscountText}
            </Typography>
          </Box>

          <Box display="flex" justifyContent="space-between" mb={2}>
            <Typography fontWeight={700} color="text.secondary">
              {formatMessage(messages.depositAmount)}
            </Typography>
            <Typography fontWeight={700} color="text.secondary">
              {depositAmountText}
            </Typography>
          </Box>
        </Grid>
        {buttonSubmitText && onSubmitClick && (
          <Grid item>
            <Button
              type="submit"
              disabled={isSubmitButtonDisabled || isSubmitLoading}
              variant="contained"
              fullWidth
              onClick={onSubmitClick}
              size="large"
            >
              {buttonSubmitText}
            </Button>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default AppointmentSummaryDetails;
