import {
  DatabaseRow,
  SelectedOfferingSide,
} from "@Shape-Digital/kudzu-data/lib/types/common";
import addHours from "date-fns/addHours";
import formatISO from "date-fns/formatISO";
import isBefore from "date-fns/isBefore";
import parseISO from "date-fns/parseISO";
import { SupabaseClient } from "@supabase/supabase-js";
import { Database } from "@Shape-Digital/kudzu-data/lib/types/supabase";
import { convertDbDateToCenterTZ } from "../../../common/dateHelpers";
import { setSupabaseAccessToken } from "../../../hooks/useSupabase";
import { getSystemConfig } from "../../../hooks/useSystemConfig";
import {
  CenterState,
  CenterServicesState,
  AddOnsState,
  DateState,
  TimeSlotState,
  SelectedCenterService,
} from "./types";
import { parseAddOn } from "./useBookingAddOns";
import { parseCenter } from "./useBookingCenters";
import { parseOffering } from "./useBookingOfferings";
import { parsePackage } from "./useBookingPackages";

type SupabaseStorageCenterConfig = Pick<
  DatabaseRow<"center_configs">,
  | "discount_amount"
  | "default_currency_code"
  | "default_offering_duration_minutes"
  | "appointment_checkout_duration_seconds"
  | "appointment_checkout_max_repeat_count"
  | "minimum_time_before_booking_seconds"
>;

type SupabaseStorageCenterAddOn = Pick<
  DatabaseRow<"center_add_ons">,
  "id" | "name" | "type" | "price" | "applies_to"
>;

type SupabaseStorageCenterOperatingHours = Pick<
  DatabaseRow<"center_operating_hours">,
  "day_of_week_number" | "from_minutes_at" | "to_minutes_at"
>;

type SupabaseStorageCenterAdress = DatabaseRow<"center_addresses">;

type SupabaseStorageCenter = DatabaseRow<"centers"> & {
  center_configs: SupabaseStorageCenterConfig[];
  center_operating_hours: SupabaseStorageCenterOperatingHours[];
  center_addresses: SupabaseStorageCenterAdress[];
};

type SupabaseStorageTimeSlot = Pick<
  DatabaseRow<"center_time_slots">,
  "type" | "started_at"
>;

type SupabaseStorageCenterOffering = Pick<
  DatabaseRow<"center_offerings">,
  | "id"
  | "name"
  | "description"
  | "deposit_amount"
  | "price"
  | "scan_side"
  | "duration_minutes"
>;

type SupabaseStorageAppointmentOffering = Pick<
  DatabaseRow<"appointment_offerings">,
  "id" | "side"
> & {
  center_offering: SupabaseStorageCenterOffering;
  appointment_add_ons: SupabaseStorageAppointmentAddOn[];
};

type SupabaseStorageAppointmentPackageOffering = Pick<
  DatabaseRow<"appointment_offerings">,
  "id" | "side"
> & {
  center_offering: SupabaseStorageCenterOffering;
};

type SupabaseStorageCenterPackage = Pick<
  DatabaseRow<"center_packages">,
  | "id"
  | "name"
  | "description"
  | "visibility"
  | "center_id"
  | "price"
  | "deposit_amount"
> & {
  center_offerings: SupabaseStorageCenterOffering[];
};

type SupabaseStorageAppointmentAddOn = {
  center_add_on: SupabaseStorageCenterAddOn;
};

type SupabaseStorageAppointmentPackage = Pick<
  DatabaseRow<"appointment_packages">,
  "id"
> & {
  center_package: SupabaseStorageCenterPackage;
  appointment_offerings: SupabaseStorageAppointmentPackageOffering[];
  appointment_add_ons: SupabaseStorageAppointmentAddOn[];
};

type SupabaseStorageAppointment = Pick<
  DatabaseRow<"appointments">,
  "id" | "status"
> & {
  center: SupabaseStorageCenter;
  center_time_slot: SupabaseStorageTimeSlot;
  appointment_offerings: SupabaseStorageAppointmentOffering[];
  appointment_packages: SupabaseStorageAppointmentPackage[];
  appointment_add_ons: SupabaseStorageAppointmentAddOn[];
};

const supabaseStorageAppointmentRequest = `
id,
status,
center:center_id (
  *,
  center_configs (
    discount_amount,
    default_currency_code,
    default_offering_duration_minutes,
    appointment_checkout_duration_seconds,
    appointment_checkout_max_repeat_count,
    minimum_time_before_booking_seconds
  ),
  center_operating_hours (
    day_of_week_number,
    from_minutes_at,
    to_minutes_at
  ),
  center_addresses (
    address,
    type
  )
),
center_time_slot:time_slot_id (
  type,
  started_at
),
appointment_offerings (
  id,
  side,
  appointment_add_ons (
    center_add_on:center_add_on_id (
      id,
      name,
      type,
      price
    )
  ),
  center_offering:center_offering_id (
    id,
    name,
    description,
    deposit_amount,
    price,
    scan_side,
    duration_minutes
  )
),
appointment_packages (
  id,
  appointment_add_ons (
    center_add_on:center_add_on_id (
      id,
      name,
      type,
      price
    )
  ),
  center_package:center_package_id (
    id,
    name,
    description,
    price,
    deposit_amount,
    visibility,
    center_id,
    center_offerings (
      id,
      name,
      description,
      deposit_amount,
      price,
      scan_side,
      duration_minutes
    )
  ),
  appointment_offerings (
    id,
    side,
    center_offering:center_offering_id (
      id,
      name,
      description,
      deposit_amount,
      price,
      scan_side,
      duration_minutes
    )
  )
),
appointment_add_ons (
  center_add_on:center_add_on_id (
    id,
    name,
    type,
    price
  )
)
`;

const STORAGE_KEY = "LAST_APPOINTMENT";
const EXPIRES_HOURS = 1;

type StorageAppointment = {
  id: string;
  center: CenterState;
  centerServices: CenterServicesState;
  addOns: AddOnsState;
  date: DateState;
  timeSlot: TimeSlotState;
  month: Date;
};

export const getStorageAppointment = async (
  supabase: SupabaseClient<Database>,
): Promise<StorageAppointment | null> => {
  const storageObjectString = sessionStorage.getItem(STORAGE_KEY);
  if (!storageObjectString) return null;

  const sessionObject = JSON.parse(storageObjectString);
  const expirationDate = parseISO(sessionObject.expiresAt);
  if (isBefore(expirationDate, new Date())) return null;

  if (sessionObject.supabaseToken) {
    setSupabaseAccessToken(sessionObject.supabaseToken);
  }

  const systemConfig = await getSystemConfig(supabase);

  const { error, data } = await supabase
    .from("appointments")
    .select(supabaseStorageAppointmentRequest)
    .eq("id", sessionObject.appointmentId)
    .is(
      "appointment_offerings.appointment_package_id" as keyof SupabaseStorageAppointment,
      null,
    )
    .is(
      "appointment_add_ons.appointment_package_id" as keyof SupabaseStorageAppointment,
      null,
    )
    .is(
      "appointment_add_ons.appointment_offering_id" as keyof SupabaseStorageAppointment,
      null,
    )
    .single<SupabaseStorageAppointment>();

  if (error) throw new Error(error.message);

  if (!data.center) return null;

  const center = parseCenter(data.center, systemConfig);

  const { defaultOfferingDurationMinutes, discountAmount } = center;

  const offerings = data.appointment_offerings.map<SelectedCenterService>(
    (appointmentOffering) => {
      const parsedOffering = parseOffering(
        appointmentOffering.center_offering,
        defaultOfferingDurationMinutes,
        discountAmount,
      );

      const addOnIds = appointmentOffering.appointment_add_ons.map(
        (addOn) => addOn.center_add_on.id,
      );

      return {
        value: parsedOffering.id,
        groupId: "single-offering",
        name: parsedOffering.name,
        price: parsedOffering.price,
        discountPrice: parsedOffering.discountPrice,
        depositAmount: parsedOffering.depositAmount,
        durationMinutes: parsedOffering.durationMinutes,
        side: (appointmentOffering.side || undefined) as SelectedOfferingSide,
        addOnIds,
        studyIds: [parsedOffering.id],
      };
    },
  );

  const packages = data.appointment_packages.map<SelectedCenterService>(
    (appointmentPackage) => {
      const parsedPackage = parsePackage(
        appointmentPackage.center_package,
        defaultOfferingDurationMinutes,
        discountAmount,
      );

      const studyIds = appointmentPackage.appointment_offerings.map(
        (offering) => offering.id,
      );

      const addOnIds = appointmentPackage.appointment_add_ons.map(
        (addOn) => addOn.center_add_on.id,
      );

      return {
        value: parsedPackage.id,
        groupId: "package",
        name: parsedPackage.name,
        price: parsedPackage.price,
        discountPrice: parsedPackage.discountPrice,
        depositAmount: parsedPackage.depositAmount,
        durationMinutes: parsedPackage.durationMinutes,
        addOnIds,
        studyIds,
      };
    },
  );

  const addOns = data.appointment_add_ons.map((appointmentAddOn) => {
    return parseAddOn(appointmentAddOn.center_add_on);
  });

  const startedAtCenterTZ = convertDbDateToCenterTZ({
    dateString: data.center_time_slot.started_at,
    timezone: center.timezone,
  });

  return {
    id: sessionObject.appointmentId,
    center,
    addOns,
    centerServices: [...offerings, ...packages],
    date: startedAtCenterTZ,
    timeSlot: startedAtCenterTZ,
    month: startedAtCenterTZ,
  };
};

export const setStorageAppointment = (
  appointmentId: string,
  supabaseToken?: string,
) => {
  const expiresAt = formatISO(addHours(new Date(), EXPIRES_HOURS));
  const sessionObject = { expiresAt, appointmentId, supabaseToken };
  sessionStorage.setItem(STORAGE_KEY, JSON.stringify(sessionObject));
};

export const clearStorageAppointment = () => {
  sessionStorage.removeItem(STORAGE_KEY);
};
