import { CheckboxGroupOption } from "../../Unknown/CheckboxGroup";

type AddOn = {
  id: string;
  name: string;
  price: number;
};

interface ParseAddOnParams {
  addOn: AddOn;
  currencyCode: string;
  checked: boolean;
  offeringsCount: number;
}

const parseAddOnToCheckboxGroupOption = (
  params: ParseAddOnParams,
): CheckboxGroupOption => {
  const { addOn, offeringsCount, currencyCode, checked } = params;
  const total = addOn.price * offeringsCount;

  return {
    value: addOn.id,
    label: addOn.name,
    price: total,
    currencyCode,
    checked,
  };
};

export default parseAddOnToCheckboxGroupOption;
