import { makeStyles } from "@mui/styles";
import { alpha, Theme } from "@mui/material";

export default makeStyles<Theme>((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: alpha(theme.palette.primary.main, 0.08),
  },
  input: {
    paddingLeft: theme.spacing(2),
  },
}));
