import { useEffect, useState } from "react";
import { createClient, SupabaseClient } from "@supabase/supabase-js";
import { Database } from "@Shape-Digital/kudzu-data/lib/types/supabase";
import { SUPABASE_CENTER_USER_TOKEN_KEY, SUPABASE_TOKEN } from "./constants";

const { REACT_APP_SUPABASE_URL, REACT_APP_SUPABASE_KEY } = process.env;

export const createSupabaseClient = (token?: string | null) => {
  if (!REACT_APP_SUPABASE_URL || !REACT_APP_SUPABASE_KEY) {
    throw new Error("Supabase client config not found");
  }

  const headers: { [key: string]: string } = {};
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  const supabaseClient = createClient(
    REACT_APP_SUPABASE_URL || "",
    REACT_APP_SUPABASE_KEY || "",
    {
      global: {
        headers,
      },
    },
  );

  return supabaseClient;
};

export const useSupabaseClient = () => {
  const [token, setToken] = useState<string | null>(null);
  const [client, setClient] = useState<SupabaseClient<Database> | null>(null);

  useEffect(() => {
    const updateClient = () => {
      const newToken =
        sessionStorage.getItem(SUPABASE_CENTER_USER_TOKEN_KEY) ||
        localStorage.getItem(SUPABASE_TOKEN);

      setToken(newToken);

      const supabaseClient = createSupabaseClient(newToken);

      setClient(supabaseClient);
    };

    window.addEventListener("storage", updateClient);

    updateClient();

    return () => {
      window.removeEventListener("storage", updateClient);
    };
  }, []);

  useEffect(() => {
    if (token) {
      const supabaseClient = createSupabaseClient(token);

      setClient(supabaseClient);
    }
  }, [token]);

  return client;
};
