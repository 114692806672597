import React from "react";
import { useField } from "formik";
import ReactPhoneInput from "../ReactPhoneInput";
import FormControl from "../FormControl";
import FormHelperText from "../FormHelperText";
import styles from "./styles";

interface FormikPhoneInputProps {
  name: string;
  required?: boolean;
  autoComplete?: "off";
  disabled?: boolean;
  fullWidth?: boolean;
  label?: string;
  onBlur?: () => void;
}

const defaultCountry = "us";

const FormikPhoneInput = ({
  name,
  required = false,
  autoComplete,
  disabled = false,
  fullWidth = false,
  onBlur,
  label,
}: FormikPhoneInputProps) => {
  const [field, meta, helpers] = useField(name);
  const { value } = field;
  const { touched, error } = meta;
  const { setTouched, setValue } = helpers;
  const isError = touched && Boolean(error);
  const sx = styles(isError);
  const labelSuffix = required ? " *" : "";
  const specialLabel = label ? `${label}${labelSuffix}` : undefined;

  return (
    <FormControl error={isError} fullWidth={fullWidth} sx={sx.formControl}>
      <ReactPhoneInput
        country={defaultCountry}
        value={value}
        onChange={(newValue) => {
          setValue(newValue);
        }}
        disabled={disabled}
        inputProps={{ name, required, autoComplete }}
        specialLabel={specialLabel}
        onBlur={() => {
          if (onBlur) {
            onBlur();
          }
          setTouched(true);
        }}
        inputStyle={sx.phoneInput}
        countryCodeEditable={false}
      />
      {isError && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

export default FormikPhoneInput;
