import { useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import { GAEventCategory } from "../common/constants";

enum GAPageViewEventAction {
  booking = "View Appointment Details page",
  checkout = "View Customer/Patient Details page",
  payments = "View Payments page",
}

const usePageViewEvent = () => {
  const prevPathRef = useRef("");
  const { pathname } = useLocation();

  useEffect(() => {
    if (prevPathRef.current !== pathname) {
      switch (true) {
        case pathname === "/":
          ReactGA.event({
            category: GAEventCategory.pageView,
            action: GAPageViewEventAction.booking,
          });
          break;
        case pathname.includes("/checkout"):
          ReactGA.event({
            category: GAEventCategory.pageView,
            action: GAPageViewEventAction.checkout,
          });
          break;
        case pathname.includes("/payment"):
          ReactGA.event({
            category: GAEventCategory.pageView,
            action: GAPageViewEventAction.payments,
          });
          break;
        default:
          return;
      }

      prevPathRef.current = pathname;
    }
  }, [pathname]);
};

export default usePageViewEvent;
