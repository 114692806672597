import {
  AddOnsState,
  CenterServicesState,
} from "../AppointmentBookingContext/types";
import { BookingAddOn } from "../AppointmentBookingContext/useBookingAddOns";
import { titleCase } from "../../../common/titleCase";

interface GetFormattedServicesParams {
  selectedAddOns: AddOnsState;
  selectedCenterServices: CenterServicesState;
  centerAddOns: BookingAddOn[];
}

export const getFormattedServices = ({
  selectedAddOns,
  selectedCenterServices,
  centerAddOns,
}: GetFormattedServicesParams) => {
  const services: string[] = [];
  const serviceAddOns: string[] = [];

  selectedCenterServices.forEach((service) => {
    if (!service) return;

    const serviceName = service?.side
      ? `${service.name} (${titleCase(service.side)})`
      : service.name;

    services.push(serviceName);

    service.addOnIds.forEach((addOnId) => {
      const addOn = centerAddOns.find(
        (centerAddOn) => centerAddOn.id === addOnId,
      );

      if (addOn) {
        const addOnName = `${addOn.name} for ${serviceName}`;
        serviceAddOns.push(addOnName);
      }
    });
  });

  const appointmentAddOns = selectedAddOns
    .map((addOn) => {
      return addOn.name;
    })
    .join(", ");

  return {
    services: services.join(", "),
    serviceAddOns: serviceAddOns.join(", "),
    appointmentAddOns,
  };
};
