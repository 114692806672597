import React, { FC, useMemo, ChangeEvent, useCallback } from "react";
import { useAppointmentBookingContext } from "../../Appointment/AppointmentBookingContext";
import CheckboxGroup from "../../Unknown/CheckboxGroup";
import Box from "../../Unknown/Box";
import parseAddOnToCheckboxGroupOption from "./parseAddOnToCheckboxGroupOption";

export interface SelectStudyAddOnsFormProps {
  currentServiceId: string;
}

const SelectStudyAddOnsForm: FC<SelectStudyAddOnsFormProps> = (props) => {
  const { currentServiceId } = props;

  const {
    selectedCenter,
    addOns,
    selectedCenterServices,
    isSubmitLoading,
    onCenterServicesChange,
  } = useAppointmentBookingContext();

  const options = useMemo(() => {
    const { data } = addOns;

    if (!selectedCenter || !data?.length) return [];

    const { defaultCurrencyCode } = selectedCenter;

    const centerService = selectedCenterServices.find(
      (service) => service?.value === currentServiceId,
    );

    return data
      .filter((addOn) => addOn.appliesTo === "study")
      .map((addOn) => {
        const checked = !!centerService?.addOnIds?.some(
          (addOnId) => addOnId === addOn.id,
        );

        return parseAddOnToCheckboxGroupOption({
          addOn,
          currencyCode: defaultCurrencyCode,
          checked,
          offeringsCount: centerService?.studyIds?.length || 0,
        });
      });
  }, [addOns, selectedCenter, selectedCenterServices, currentServiceId]);

  const onChecked = useCallback(
    (addOnId?: string): void => {
      const { data } = addOns;
      if (!selectedCenter || !data?.length || !addOnId) return;
      onCenterServicesChange((prev) => {
        const selectedService = prev.find(
          (service) => service?.value === currentServiceId,
        );

        if (selectedService) {
          const updatedAddOnIds = selectedService.addOnIds
            ? [...selectedService.addOnIds, addOnId]
            : [addOnId];

          return prev.map((service) =>
            service?.value === currentServiceId
              ? { ...service, addOnIds: updatedAddOnIds }
              : service,
          );
        }

        return prev;
      });
    },
    [addOns, onCenterServicesChange, selectedCenter, currentServiceId],
  );

  const onUnchecked = useCallback(
    (addOnId?: string) => {
      if (!addOnId) return;

      onCenterServicesChange((prev) => {
        const updatedServices = prev.map((service) => {
          if (service?.value === currentServiceId) {
            return {
              ...service,
              addOnIds: service.addOnIds?.filter((id) => id !== addOnId) || [],
            };
          }
          return service;
        });

        return updatedServices;
      });
    },
    [onCenterServicesChange, currentServiceId],
  );

  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>, checked: boolean): void => {
      const actionFunc = checked ? onChecked : onUnchecked;
      actionFunc(e?.target?.value);
    },
    [onChecked, onUnchecked],
  );

  if (!selectedCenter || !addOns.data?.length || !options.length) return null;

  return (
    <Box mt={2}>
      <CheckboxGroup
        dense
        options={options}
        onChange={onChange}
        disabled={isSubmitLoading}
      />
    </Box>
  );
};

export default SelectStudyAddOnsForm;
