/* eslint-disable @typescript-eslint/naming-convention */
import Big from "big.js";
import { useCallback, useEffect, useMemo } from "react";
import {
  DatabaseEnum,
  DatabaseRow,
} from "@Shape-Digital/kudzu-data/lib/types/common";
import useDataState from "../../../hooks/useDataState";
import { useSupabaseClient } from "../../../common/supabaseClient";

export type SupabaseBookingOffering = Pick<
  DatabaseRow<"center_offerings">,
  | "id"
  | "name"
  | "description"
  | "deposit_amount"
  | "price"
  | "scan_side"
  | "duration_minutes"
>;

const supabaseBookingOfferingsRequest = `
id,
name,
description,
deposit_amount,
price,
scan_side,
duration_minutes
`;

export type BookingOffering = {
  id: string;
  name: string;
  description: string;
  depositAmount: number;
  price: number;
  scanSide: "single" | "dual" | null;
  durationMinutes: number;
  discountPrice: number;
  studyIds: string[];
  addOnIds: string[];
};

export const parseOffering = (
  offering: SupabaseBookingOffering,
  defaultOfferingDurationMinutes: number,
  discountAmount: number,
): BookingOffering => {
  const {
    id,
    name,
    description,
    deposit_amount,
    price,
    scan_side,
    duration_minutes,
  } = offering;

  const durationMinutes =
    duration_minutes !== null
      ? duration_minutes
      : defaultOfferingDurationMinutes;

  const discountPrice = discountAmount
    ? Big(price).minus(discountAmount).toNumber()
    : price;

  return {
    id,
    name,
    description,
    depositAmount: deposit_amount,
    price,
    scanSide: scan_side,
    durationMinutes,
    discountPrice,
    studyIds: [id],
    addOnIds: [],
  };
};

type OfferingsCenterConfig = {
  id: string;
  defaultOfferingDurationMinutes: number;
  discountAmount: number;
};

const useBookingOfferings = (params: {
  centerConfig: OfferingsCenterConfig | null;
  isCenterUser: boolean;
}) => {
  const { centerConfig, isCenterUser } = params;

  const supabase = useSupabaseClient();

  const { dataState, updateDataState } = useDataState<BookingOffering[]>();

  const visibility = useMemo<DatabaseEnum["offering_visibility"][]>(() => {
    const privateVisibility = isCenterUser ? ["private" as const] : [];
    return ["public", ...privateVisibility];
  }, [isCenterUser]);

  const getOfferingsRequest = useCallback(async () => {
    try {
      if (!centerConfig || !supabase) return;

      const { id, defaultOfferingDurationMinutes, discountAmount } =
        centerConfig;

      updateDataState({ status: "loading" });

      const response = await supabase
        .from("center_offerings")
        .select(supabaseBookingOfferingsRequest)
        .is("center_package_id", null)
        .in("visibility", visibility)
        .eq("center_id", id)
        .returns<DatabaseRow<"center_offerings">[]>();

      if (response.error) {
        throw new Error(response.error.message);
      }

      if (response.data) {
        const parsedOfferings = response.data.map((offering) =>
          parseOffering(
            offering,
            defaultOfferingDurationMinutes,
            discountAmount,
          ),
        );

        updateDataState({ status: "success", data: parsedOfferings });
      }
    } catch (err) {
      updateDataState({ status: "error", error: (err as Error).message });
    }
  }, [centerConfig, supabase, updateDataState, visibility]);

  useEffect(() => {
    getOfferingsRequest();
  }, [getOfferingsRequest]);

  return dataState;
};

export default useBookingOfferings;
