import { DatabaseRow } from "@Shape-Digital/kudzu-data/lib/types/common";
import { useState } from "react";
import { useIntl } from "react-intl";
import { useDebounce } from "react-use";
import { useSupabaseClient } from "../../../common/supabaseClient";
import messages from "./messages";

const useCustomers = ({ searchValue }: { searchValue: string }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [customers, setCustomers] = useState<DatabaseRow<"customers">[]>([]);
  const [errorMessage, setErrorMessage] = useState("");
  const { formatMessage } = useIntl();
  const supabase = useSupabaseClient();

  useDebounce(
    async () => {
      if (!supabase) return;
      if (searchValue.length >= 3) {
        try {
          setErrorMessage("");
          setIsLoading(true);
          setCustomers([]);
          const { data: databaseCustomers, error: customersError } =
            await supabase
              .rpc("customer__search_customer", {
                search_text: searchValue,
              })
              .returns<DatabaseRow<"customers">[]>();

          if (customersError) {
            throw new Error(customersError.message);
          }

          setCustomers(databaseCustomers || []);
          setIsLoading(false);
        } catch (error) {
          setErrorMessage(formatMessage(messages.searchErrorMessage));
        }
      }

      if (searchValue.length < 3) {
        setCustomers([]);
      }
    },
    300,
    [searchValue],
  );

  return { customers, isLoading, errorMessage };
};

export default useCustomers;
