/* eslint-disable @typescript-eslint/naming-convention */
import { useCallback, useEffect } from "react";
import { DatabaseRow } from "@Shape-Digital/kudzu-data/lib/types/common";
import useDataState from "../../../hooks/useDataState";
import { useSupabaseClient } from "../../../common/supabaseClient";

export type SupabaseBookingAddOn = Pick<
  DatabaseRow<"center_add_ons">,
  "id" | "name" | "type" | "price" | "applies_to"
>;

const supabaseBookingAddOnsRequest = `
id,
name,
type,
applies_to,
price,
visibility
`;

export type AddOnAppliesTo = "study" | "appointment";

export type BookingAddOn = {
  id: string;
  name: string;
  type: string;
  price: number;
  appliesTo: AddOnAppliesTo;
};

export const parseAddOn = (addOn: SupabaseBookingAddOn): BookingAddOn => {
  return {
    id: addOn.id,
    name: addOn.name,
    type: addOn.type,
    price: addOn.price,
    appliesTo: (addOn.applies_to || "appointment") as AddOnAppliesTo,
  };
};

const useBookingAddOns = (centerId?: string) => {
  const supabase = useSupabaseClient();

  const { dataState, updateDataState } = useDataState<BookingAddOn[]>();

  const getAddOnsRequest = useCallback(async () => {
    try {
      if (!centerId || !supabase) return;

      updateDataState({ status: "loading" });

      const response = await supabase
        .from("center_add_ons")
        .select(supabaseBookingAddOnsRequest)
        .eq("center_id", centerId)
        .eq("visibility", "public")
        .returns<DatabaseRow<"center_add_ons">[]>();

      if (response.error) {
        throw new Error(response.error.message);
      }

      if (response.data) {
        const parsedAddOns = response.data.map(parseAddOn);
        updateDataState({ status: "success", data: parsedAddOns });
      }
    } catch (err) {
      updateDataState({ status: "error", error: (err as Error).message });
    }
  }, [centerId, supabase, updateDataState]);

  useEffect(() => {
    getAddOnsRequest();
  }, [getAddOnsRequest]);

  return dataState;
};

export default useBookingAddOns;
