import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import AppointmentCheckoutScreen from "../../Appointment/AppointmentCheckoutScreen";
import { AuthContextProvider } from "../../Auth/AuthContextProvider";
import { AppointmentBookingContextProvider } from "../../Appointment/AppointmentBookingContext";
import AppointmentStripePaymentScreen from "../../Payment/AppointmentStripePaymentScreen/index";
import AppointmentConfirmedScreen from "../../Appointment/AppointmentConfirmedScreen";
import AppointmentBookingScreen from "../../Appointment/AppointmentBookingScreen";
import usePageViewEvent from "../../../hooks/usePageViewEvent";

const Root: React.FC = () => {
  usePageViewEvent();

  return (
    <Routes>
      <Route
        path="/"
        element={
          <AuthContextProvider>
            <AppointmentBookingContextProvider>
              <AppointmentBookingScreen />
            </AppointmentBookingContextProvider>
          </AuthContextProvider>
        }
      />
      <Route
        path="/:appointmentId/checkout"
        element={
          <AuthContextProvider>
            <AppointmentCheckoutScreen />
          </AuthContextProvider>
        }
      />
      <Route
        path="/:appointmentId/payment/stripe"
        element={
          <AuthContextProvider>
            <AppointmentStripePaymentScreen />
          </AuthContextProvider>
        }
      />
      <Route
        path="/:appointmentId/success"
        element={
          <AuthContextProvider>
            <AppointmentConfirmedScreen />
          </AuthContextProvider>
        }
      />
      <Route path="*" element={<Navigate replace to="/" />} />
    </Routes>
  );
};

export default Root;
