import React, { FC } from "react";
import { useField } from "formik";
import TextField, { TextFieldProps } from "../TextField";

type FormikTextFieldProps = {
  name: string;
  onBlur?: () => void;
} & Omit<
  TextFieldProps,
  | "value"
  | "multiline"
  | "onChange"
  | "onMouseDown"
  | "onFocus"
  | "onBlur"
  | "error"
>;

const FormikTextField: FC<FormikTextFieldProps> = ({
  name,
  helperText,
  onBlur,
  ...props
}) => {
  const [field, meta] = useField(name);
  const { touched, error } = meta;

  const innerHelperText = touched && Boolean(error) ? error : helperText;

  const handleBlur = (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    field.onBlur(e);
    if (onBlur) {
      onBlur();
    }
  };

  return (
    <TextField
      {...props}
      {...field}
      onBlur={handleBlur}
      value={field.value || ""}
      error={touched && Boolean(error)}
      helperText={innerHelperText}
    />
  );
};

export default FormikTextField;
