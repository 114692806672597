import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { DatabaseRow } from "@Shape-Digital/kudzu-data/lib/types/common";
import useTranslations from "./useTranslations";
import { SUPABASE_CENTER_USER_TOKEN_KEY } from "../../../common/constants";
import { createSupabaseClient } from "../../../common/supabaseClient";

const useIsCenterUser = ({
  userId,
  centerUserFirebaseToken,
}: {
  userId: string | null;
  centerUserFirebaseToken: string | null;
}) => {
  const [isCenterUser, setIsCenterUser] = useState(false);
  const [isTokenChecked, setIsTokenChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const translations = useTranslations();

  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const centerUserToken = queryParams.get("token");
  const supabaseCenterUserToken = sessionStorage.getItem(
    SUPABASE_CENTER_USER_TOKEN_KEY,
  );

  useEffect(() => {
    if (isTokenChecked || isLoading || supabaseCenterUserToken === undefined)
      return;

    if (
      !userId &&
      !supabaseCenterUserToken &&
      !centerUserToken &&
      !centerUserFirebaseToken
    ) {
      setIsTokenChecked(true);
    }

    if (
      userId &&
      supabaseCenterUserToken &&
      (centerUserToken || centerUserFirebaseToken)
    ) {
      const supabase = createSupabaseClient(supabaseCenterUserToken);

      const getSupabaseUser = async () => {
        if (!supabase) return;
        try {
          setIsLoading(true);
          const { data: userData, error } = await supabase
            .from("center_users")
            .select("*")
            .eq("id", userId)
            .maybeSingle<DatabaseRow<"center_users">>();

          if (error) {
            throw new Error("Unknown error");
          }

          setIsCenterUser(!!userData);
          setIsTokenChecked(true);
        } catch (error) {
          setErrorMessage(translations.defaultError);
        } finally {
          setIsLoading(false);
        }
      };

      getSupabaseUser();
    }
  }, [
    centerUserFirebaseToken,
    centerUserToken,
    isLoading,
    isTokenChecked,
    supabaseCenterUserToken,
    translations.defaultError,
    userId,
  ]);

  return { isCenterUser, isLoading, errorMessage, isTokenChecked };
};

export default useIsCenterUser;
