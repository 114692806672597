/* eslint-disable @typescript-eslint/naming-convention */
import { parseISO, subMinutes } from "date-fns";
import { DatabaseRow } from "@Shape-Digital/kudzu-data/lib/types/common";
import { SystemConfig } from "../../../hooks/useSystemConfig";
import { convertDbDateToCenterTZ } from "../../../common/dateHelpers";

type SupabaseCheckoutCenterConfig = Pick<
  DatabaseRow<"center_configs">,
  | "default_currency_code"
  | "appointment_checkout_duration_seconds"
  | "appointment_checkout_max_repeat_count"
>;

type SupabaseCheckoutCenterAddress = DatabaseRow<"center_addresses">;

type SupabaseCheckoutCenter = Pick<
  DatabaseRow<"centers">,
  | "id"
  | "name"
  | "country"
  | "city"
  | "postal_code"
  | "region"
  | "phone_number"
  | "email"
  | "fax_number"
  | "timezone"
  | "state"
> & {
  center_configs: SupabaseCheckoutCenterConfig[];
  center_addresses: SupabaseCheckoutCenterAddress[];
  center_payment_methods: CenterPaymentMethod[];
  center_payment_provider_readers: CenterPaymentProviderReader[];
};

type SupabaseCheckoutCenterOffering = Pick<
  DatabaseRow<"center_offerings">,
  "name" | "id"
>;

type SupabaseCheckoutAppointmentOffering = Pick<
  DatabaseRow<"appointment_offerings">,
  "total_cost_amount" | "side"
> & {
  center_offering: SupabaseCheckoutCenterOffering;
  appointment_add_ons: SupabaseCheckoutAppointmentAddOn[];
};

type SupabaseCheckoutCenterPackage = Pick<
  DatabaseRow<"center_packages">,
  "name"
> & {
  center_offerings: SupabaseCheckoutCenterOffering[];
  appointment_add_ons: SupabaseCheckoutAppointmentAddOn[];
};

type SupabaseCheckoutAppointmentPackage = Pick<
  DatabaseRow<"appointment_packages">,
  "total_cost_amount"
> & {
  center_package: SupabaseCheckoutCenterPackage;
  appointment_add_ons: SupabaseCheckoutAppointmentAddOn[];
  appointment_offerings: Pick<DatabaseRow<"appointment_offerings">, "id">[];
};

type SupabaseCheckoutCenterAddOn = Pick<
  DatabaseRow<"center_add_ons">,
  "name" | "id"
>;

type SupabaseCheckoutAppointmentAddOn = Pick<
  DatabaseRow<"appointment_add_ons">,
  "total_cost_amount"
> & {
  center_add_on: SupabaseCheckoutCenterAddOn;
};

type SupabaseCheckoutTimeSlot = Pick<
  DatabaseRow<"center_time_slots">,
  "started_at" | "expires_at"
>;

type AppointmentCustomerDetails = Pick<
  DatabaseRow<"appointment_customer_details">,
  "id" | "customer_id"
>;

export type SupabaseCheckoutAppointment = Pick<
  DatabaseRow<"appointments">,
  | "checkout_repeat_count"
  | "status"
  | "deposit_amount"
  | "discount_amount"
  | "total_cost_amount"
> & {
  center: SupabaseCheckoutCenter;
  appointment_offerings: SupabaseCheckoutAppointmentOffering[];
  appointment_packages: SupabaseCheckoutAppointmentPackage[];
  appointment_add_ons: SupabaseCheckoutAppointmentAddOn[];
  time_slot: SupabaseCheckoutTimeSlot;
  appointment_customer_details: AppointmentCustomerDetails[];
};

export const supabaseCheckoutAppointmentRequest = `
checkout_repeat_count,
status,
deposit_amount,
discount_amount,
total_cost_amount,
center:center_id (
  id,
  name,
  country,
  city,
  postal_code,
  region,
  phone_number,
  email,
  fax_number,
  timezone,
  state,
  center_configs (
    default_currency_code,
    appointment_checkout_duration_seconds,
    appointment_checkout_max_repeat_count
  ),
  center_addresses (
    address,
    type
  ),
  center_payment_provider_readers (
    center_payment_provider_id (
      payment_provider_id
    ),
    payment_provider_reader_id,
    payment_provider_reader_label
  ),
  center_payment_methods (
    id,
    center_payment_provider: center_payment_provider_id(
      payment_provider: payment_provider_id (
        type
      )
    ),
    payment_method: payment_method_id (
      id,
      type
    )
  )
),
appointment_customer_details (
  id,
  customer_id
),
appointment_offerings (
  side,
  total_cost_amount,
  center_offering:center_offering_id (
    id,
    name
  ),
  appointment_add_ons (
    total_cost_amount,
    center_add_on:center_add_on_id (
      id,
      name
    )
  )
),
appointment_packages (
  total_cost_amount,
  center_package:center_package_id (
    name
  ),
  appointment_offerings (
    center_offering:center_offering_id (
      id,
      name
    )
  ),
  appointment_add_ons (
    total_cost_amount,
    center_add_on:center_add_on_id (
      id,
      name
    )
  )
),
appointment_add_ons (
  total_cost_amount,
  center_add_on:center_add_on_id (
    name
  )
),
time_slot:time_slot_id (
  started_at,
  expires_at
)
`;

export type CheckoutAppointmentCenterService = {
  name: string;
  price: number;
  side?: string;
  addOnIds: string[];
  studyIds: string[];
};

export type CheckoutAppointmentAddOn = {
  name: string;
  price: number;
};

type PaymentMethod = Pick<DatabaseRow<"payment_methods">, "id" | "type">;

type CenterPaymentProvider = {
  payment_provider: DatabaseRow<"payment_providers">;
};

type CenterPaymentMethod = Pick<
  DatabaseRow<"center_payment_methods">,
  "id" | "center_payment_provider_id"
> & {
  payment_method: PaymentMethod;
  center_payment_provider: CenterPaymentProvider;
};

type CenterPaymentProviderReader =
  DatabaseRow<"center_payment_provider_readers">;

export type CheckoutCenter = {
  id: string;
  name: string;
  country: string;
  city: string;
  postalCode: string;
  region: string;
  phoneNumber: string;
  email: string;
  addresses: DatabaseRow<"center_addresses">[];
  state: string;
  faxNumber: string | null;
  defaultCurrencyCode: string;
  appointmentCheckoutDurationSeconds: number;
  appointmentCheckoutMaxRepeatCount: number;
  centerPaymentMethods: CenterPaymentMethod[];
  centerPaymentProviderReaders: CenterPaymentProviderReader[];
};

export type CheckoutAppointmentTimeSlot = {
  startedAt: Date;
  expiresAt: Date;
};

export type CheckoutAppointment = {
  checkoutRepeatCount: number;
  status: SupabaseCheckoutAppointment["status"];
  depositAmount: number;
  discountAmount: number;
  totalCostAmount: number;
  center: CheckoutCenter;
  offerings: CheckoutAppointmentCenterService[];
  packages: CheckoutAppointmentCenterService[];
  addOns: CheckoutAppointmentAddOn[];
  timeSlot: CheckoutAppointmentTimeSlot;
  appointmentCustomerDetails: AppointmentCustomerDetails[];
};

const parseCheckoutAppointment = (
  supabaseAppointment: SupabaseCheckoutAppointment,
  systemConfig: SystemConfig,
): CheckoutAppointment => {
  const {
    checkout_repeat_count,
    status,
    deposit_amount,
    discount_amount,
    total_cost_amount,
    center,
    appointment_offerings,
    appointment_packages,
    appointment_add_ons,
    time_slot,
    appointment_customer_details,
  } = supabaseAppointment;

  if (!time_slot.expires_at) {
    throw new Error("Expires at is undefined");
  }

  const {
    id,
    name,
    country,
    city,
    postal_code,
    region,
    phone_number,
    fax_number,
    email,
    center_configs,
    timezone,
    state,
    center_addresses,
  } = center;

  const appointmentCenter: CheckoutCenter = {
    id,
    name,
    country,
    city,
    postalCode: postal_code,
    region,
    phoneNumber: phone_number,
    faxNumber: fax_number,
    email,
    state,
    addresses: center_addresses,
    defaultCurrencyCode:
      center_configs[0]?.default_currency_code ??
      systemConfig.defaultCurrencyCode,

    appointmentCheckoutDurationSeconds:
      center_configs[0]?.appointment_checkout_duration_seconds ??
      systemConfig.appointmentCheckoutDurationSeconds,

    appointmentCheckoutMaxRepeatCount:
      center_configs[0]?.appointment_checkout_max_repeat_count ??
      systemConfig.appointmentCheckoutMaxRepeatCount,
    centerPaymentMethods: center.center_payment_methods,
    centerPaymentProviderReaders: center.center_payment_provider_readers,
  };

  const appointmentOfferings = appointment_offerings.map((offering) => ({
    name: offering.center_offering.name,
    price: offering.total_cost_amount,
    side: offering.side || undefined,
    studyIds: [offering.center_offering.id],
    addOnIds: offering.appointment_add_ons.map(
      (addOn) => addOn.center_add_on.id,
    ),
  }));

  const appointmentPackages =
    appointment_packages.map<CheckoutAppointmentCenterService>(
      (packageItem) => ({
        name: packageItem.center_package.name,
        price: packageItem.total_cost_amount,
        addOnIds: packageItem.appointment_add_ons.map(
          (addOn) => addOn.center_add_on.id,
        ),
        studyIds: packageItem.appointment_offerings.map((study) => study.id),
      }),
    );

  const appointmentAddOns = appointment_add_ons.map((addOn) => ({
    name: addOn.center_add_on.name,
    price: addOn.total_cost_amount,
  }));

  const appointmentTimeSlot: CheckoutAppointmentTimeSlot = {
    startedAt: convertDbDateToCenterTZ({
      dateString: time_slot.started_at,
      timezone,
    }),
    expiresAt: subMinutes(
      parseISO(time_slot.expires_at),
      new Date().getTimezoneOffset(),
    ),
  };

  return {
    checkoutRepeatCount: checkout_repeat_count || 0,
    status,
    depositAmount: deposit_amount,
    discountAmount: discount_amount,
    totalCostAmount: total_cost_amount,
    center: appointmentCenter,
    offerings: appointmentOfferings,
    packages: appointmentPackages,
    addOns: appointmentAddOns,
    timeSlot: appointmentTimeSlot,
    appointmentCustomerDetails: appointment_customer_details,
  };
};

export default parseCheckoutAppointment;
