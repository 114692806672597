import React, {
  FC,
  useState,
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
} from "react";
import { useParams } from "react-router-dom";
import Big from "big.js";
import { DatabaseRow } from "@Shape-Digital/kudzu-data/lib/types/common";
import { LogInFormPersonType } from "@Shape-Digital/kudzu-data/lib/types/actions";
import Box from "../../Unknown/Box";
import CircularProgress from "../../Unknown/CircularProgress";
import AppointmentCheckoutFormChoice from "../AppointmentCheckoutFormChoice";
import AppointmentCheckoutFormSection from "../AppointmentCheckoutFormSection";
import AppointmentNotPossibleDialog from "../AppointmentNotPossibleDialog";
import useAppointmentNotPossibleDialog from "../AppointmentNotPossibleDialog/useAppointmentNotPossibleDialog";
import { useAuthContext } from "../../Auth/AuthContextProvider";
import useTranslations from "./useTranslations";
import AppointmentWelcomeDialog from "../AppointmentWelcomeDialog";
import useAppointmentWelcomeDialog from "../AppointmentWelcomeDialog/useAppointmentWelcomeDialog";
import useCustomerFormData from "./useCustomerFormData";
import AppointmentCheckoutFormSectionWrapper from "../AppointmentCheckoutFormSectionWrapper";
import useCheckoutAppointment from "./useCheckoutAppointment";
import AppointmentLayout from "../AppointmentLayout";
import AppointmentCheckoutTimer from "../AppointmentCheckoutTimer";
import useAppointmentCheckoutTimer from "../AppointmentCheckoutTimer/useAppointmentCheckoutTimer";
import useDeviceType from "../../../hooks/useDeviceType";
import useBookingAddOns from "../AppointmentBookingContext/useBookingAddOns";

const getPersonType = (
  isPatient: boolean | null,
  isGuardian: boolean | null,
): LogInFormPersonType | null => {
  if (isPatient) return "patient";
  if (!isPatient && isGuardian) return "legalGuardian";
  if (!isPatient && isGuardian === false) return "otherPerson";
  return null;
};

const AppointmentCheckoutScreen: FC = () => {
  const { appointmentId } = useParams();
  const { translations } = useTranslations();
  const { isMobile } = useDeviceType();
  const { buttonDesktopSubmitText, buttonMobileSubmitText } = translations;

  const [isPatient, setIsPatient] = useState<boolean | null>(null);
  const [isGuardian, setIsGuardian] = useState<boolean | null>(null);
  const [selectedCustomer, setSelectedCustomer] =
    useState<DatabaseRow<"customers"> | null>(null);

  const [isLoginLoading, setIsLoginLoading] = useState(false);
  const [isCodeSent, setIsCodeSent] = useState(false);

  const { signIn, isAuthenticated, answerCustomChallenge } = useAuthContext();

  const { data: customerFormData, status: customerFormDataStatus } =
    useCustomerFormData();

  const { data: appointmentData, status: appointmentStatus } =
    useCheckoutAppointment(appointmentId);

  const {
    isNotPossibleDialogOpen,
    notPossibleQuestion,
    onNotPossibleAnswerClick,
    onNotPossibleClose,
  } = useAppointmentNotPossibleDialog();

  const onSelectCustomer = (customer: DatabaseRow<"customers">) => {
    setSelectedCustomer(customer);
  };

  const personType = useMemo<LogInFormPersonType | null>(
    () => getPersonType(isPatient, isGuardian),
    [isGuardian, isPatient],
  );

  const {
    onWelcomeOpen,
    welcomeEmail,
    isWelcomeDialogOpen,
    onWelcomeResendButtonClick,
    onWelcomeClose,
    formik,
  } = useAppointmentWelcomeDialog({ appointmentId, answerCustomChallenge });

  const appointmentCheckoutTimerProps = useAppointmentCheckoutTimer({
    appointmentId,
    appointmentData,
  });

  const { data: centerAddOns, status: addOnsStatus } = useBookingAddOns(
    appointmentData?.center?.id,
  );

  const { isCenterUser, isCenterUserLoading } = useAuthContext();

  const onEmailBlur = useCallback(
    async (email: string, valid: boolean) => {
      if (!email || !valid || isAuthenticated) {
        return;
      }

      try {
        setIsLoginLoading(true);

        const isSent = await signIn({ email });
        setIsCodeSent(isSent);
        if (isSent) onWelcomeOpen(email);
      } catch (error) {
        // eslint-disable-next-line no-console, no-alert
        alert(translations.emailCanNotBeUsed);
      } finally {
        setIsLoginLoading(false);
      }
    },
    [isAuthenticated, onWelcomeOpen, signIn, translations.emailCanNotBeUsed],
  );

  const priceWithDiscount = useMemo(() => {
    if (appointmentStatus !== "success") return 0;
    const { totalCostAmount, discountAmount } = appointmentData;

    return Big(totalCostAmount).minus(discountAmount).toNumber();
  }, [appointmentData, appointmentStatus]);

  const onResendClick = useCallback(async () => {
    try {
      setIsLoginLoading(true);
      onWelcomeResendButtonClick();

      const isSent = await signIn({ email: welcomeEmail });
      setIsCodeSent(isSent);
      if (isSent) onWelcomeOpen(welcomeEmail);
    } catch (error) {
      // eslint-disable-next-line no-alert
      alert(translations.emailCanNotBeUsed);
    } finally {
      setIsLoginLoading(false);
    }
  }, [
    onWelcomeOpen,
    onWelcomeResendButtonClick,
    signIn,
    translations.emailCanNotBeUsed,
    welcomeEmail,
  ]);

  if (!appointmentId) {
    return null;
  }

  if (
    appointmentStatus !== "success" ||
    customerFormDataStatus !== "success" ||
    !appointmentCheckoutTimerProps ||
    isCenterUserLoading ||
    addOnsStatus !== "success"
  ) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexGrow={1}
        mt={10}
      >
        <CircularProgress />
      </Box>
    );
  }

  const {
    center,
    offerings,
    packages,
    addOns,
    timeSlot,
    depositAmount,
    discountAmount,
  } = appointmentData;

  const confirmAndPayText = isMobile
    ? buttonMobileSubmitText
    : buttonDesktopSubmitText;

  const isPaymentRequired = priceWithDiscount > 0 && depositAmount > 0;

  const buttonSubmitText = isPaymentRequired
    ? confirmAndPayText
    : translations.saveAppointment;

  return (
    <AppointmentCheckoutFormSectionWrapper
      personType={personType}
      customerFormData={customerFormData}
      selectedCustomer={selectedCustomer}
      isPaymentRequired={isPaymentRequired}
    >
      {({ isValid, dirty, resetForm, handleSubmit, isSubmitting }) => {
        const isEmailInUse = isCodeSent && !customerFormData?.customer.email;

        const isSubmitButtonDisabled =
          isEmailInUse ||
          isPatient === null ||
          (isPatient === false && isGuardian === null) ||
          !isValid ||
          !dirty ||
          isSubmitting;

        const onIsPatientChange: Dispatch<SetStateAction<boolean | null>> = (
          val,
        ) => {
          resetForm();
          setIsPatient(val);
          setIsGuardian(null);
        };

        const onIsGuardianChange: Dispatch<SetStateAction<boolean | null>> = (
          val,
        ) => {
          resetForm();
          setIsGuardian(val);
        };

        return (
          <AppointmentLayout
            selectedCenter={center}
            selectedDateTime={timeSlot.startedAt}
            selectedCenterServices={[...offerings, ...packages]}
            selectedAddOns={addOns}
            onSubmitClick={handleSubmit}
            isSubmitButtonDisabled={isSubmitButtonDisabled}
            isSubmitLoading={isSubmitting}
            buttonSubmitText={buttonSubmitText}
            depositAmount={depositAmount}
            discountSum={discountAmount}
            centerAddOns={centerAddOns || []}
            priceWithDiscount={priceWithDiscount}
          >
            <AppointmentCheckoutTimer {...appointmentCheckoutTimerProps} />
            <AppointmentCheckoutFormChoice
              isPatient={isPatient}
              setIsPatient={onIsPatientChange}
              isGuardian={isGuardian}
              setIsGuardian={onIsGuardianChange}
              isDisabled={isLoginLoading}
            />
            <Box mt={8}>
              <AppointmentCheckoutFormSection
                personType={personType}
                onNotPossibleAnswerClick={onNotPossibleAnswerClick}
                isEmailLoading={isLoginLoading}
                isEmailInUse={isEmailInUse}
                isCheckDataShown={!!customerFormData}
                onEmailBlur={isCenterUser ? undefined : onEmailBlur}
                isCenterUser={isCenterUser}
                onSelectCustomer={onSelectCustomer}
              />
            </Box>
            <AppointmentNotPossibleDialog
              question={notPossibleQuestion}
              centerName={center.name}
              phoneNumber={center.phoneNumber}
              isOpen={isNotPossibleDialogOpen}
              onClose={onNotPossibleClose}
            />
            <AppointmentWelcomeDialog
              email={welcomeEmail}
              isOpen={isWelcomeDialogOpen}
              onResendClick={onResendClick}
              onClose={onWelcomeClose}
              formik={formik}
            />
          </AppointmentLayout>
        );
      }}
    </AppointmentCheckoutFormSectionWrapper>
  );
};

export default AppointmentCheckoutScreen;
