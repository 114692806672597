import React from "react";

import Box from "../../Unknown/Box";
import Typography from "../../Unknown/Typography";

export interface SummaryPositionItemProps {
  name: string;
  price: string;
}

export const SummaryPositionItem: React.FC<SummaryPositionItemProps> = ({
  name,
  price,
}) => {
  return (
    <Box
      key={name}
      display="flex"
      gap={2}
      justifyContent="space-between"
      mb={2}
    >
      <Box mr={2}>
        <Typography sx={{ wordBreak: "break-word" }}>{name}</Typography>
      </Box>
      <Typography>{price}</Typography>
    </Box>
  );
};
